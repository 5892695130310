// Central location to store flags.

import { UserExperimentFlags, UUID } from '../lib/client/v1/entities';
import { stringToEnum } from './helpers';
import { UserInfo } from './types';

export enum Flavor {
  Local = 'local',
  Test = 'test',
  Staging = 'staging',
  Production = 'production',
}

const WS_ENDPOINT_OVERRIDE_KEY = 'ws-endpoint-override';
const WS_USER_TO_IMPERSONATE_LS_KEY = 'ws-impersonation-target';

export function getBuildFlavor(): Flavor {
  // The flavor comes from a property specified in the docker file.
  const flavor = process.env.NEXT_PUBLIC_WS_APP_ENV;
  switch (flavor) {
    case 'production':
      return Flavor.Production;
    case 'staging':
      return Flavor.Staging;
    case 'test':
      return Flavor.Test;
    case 'local':
      return Flavor.Local;
    default:
      return Flavor.Local;
  }
}

export function getBuildVersion(): string {
  return process.env.NEXT_PUBLIC_WS_BUILD_VERSION ?? '00.00.00.00.00.DEV';
}

export function getFrontEndBaseUrl(flavor: Flavor): string {
  switch (flavor) {
    case Flavor.Production:
      return 'https://app.whalesync.com';
    case Flavor.Staging:
      return 'https://staging.whalesync.com';
    case Flavor.Test:
      return 'https://test.whalesync.com';
    case Flavor.Local:
      return 'http://localhost:3030';
  }
}

export function getStoredEndpointOverride(): Flavor | null {
  if (typeof window !== 'undefined') {
    const localStorageOverride = localStorage.getItem(WS_ENDPOINT_OVERRIDE_KEY);
    if (localStorageOverride) {
      return stringToEnum(localStorageOverride, Flavor, null);
    }
  }
  return null;
}

export function setStoredEndpointOverride(endpointOverride: Flavor): void {
  if (typeof window !== 'undefined') {
    localStorage.setItem(WS_ENDPOINT_OVERRIDE_KEY, endpointOverride.toString());
  }
}

export function getStoredUserToImpersonateId(): UUID | '' {
  if (typeof window !== 'undefined') {
    return (localStorage.getItem(WS_USER_TO_IMPERSONATE_LS_KEY) as UUID) ?? '';
  }
  return '';
}

export function setStoredUserToImpersonateId(newValue: string): void {
  if (typeof window !== 'undefined') {
    localStorage.setItem(WS_USER_TO_IMPERSONATE_LS_KEY, newValue);
  }
}
export function isExperimentEnabled(experiment: keyof UserExperimentFlags, user: UserInfo | null): boolean {
  return user?.whalesyncUser.experimentFlags[experiment] === true;
}

// NOTE: This does not provide any hooks for when the value changes.
export class ClientFlag {
  private value: boolean;

  constructor(
    readonly friendlyName: string,
    readonly devName: string,
    defaultValue: boolean,
  ) {
    const rawValue = typeof localStorage !== 'undefined' ? localStorage.getItem(this.devName) : defaultValue;
    if (rawValue === 'true') {
      this.value = true;
    } else if (rawValue === 'false') {
      this.value = false;
    } else {
      this.value = defaultValue;
    }
  }

  get(): boolean {
    return this.value;
  }

  getLocalStorageValue(): boolean {
    return this.get();
  }

  setLocalStorageValue(newValue: boolean): void {
    this.value = newValue;
    localStorage.setItem(this.devName, this.value ? 'true' : 'false');
  }
}

export const FLAGS = {
  SKIP_PAYWALL_FOR_LOCALHOST: new ClientFlag('Skip paywall on localhost', 'skip_paywall_locally', true),
  VIEW_CMRS: new ClientFlag('View CMRs', 'view_cmrs', false),
  INVISIBLE_DEV_TOOLBOX: new ClientFlag('Invisible dev toolbox', 'invisibleDevToolbox', false),
  ALLOW_MAPPING_UNSUPPORTED_FIELDS: new ClientFlag(
    'Allow mapping unsupported fields',
    'allowMappingUnsupportedFields',
    false,
  ),
  TOGGLE_NEW_UI: new ClientFlag('Toggle to New UI (v1.5)', 'toggleNewUI', false),
};

// This is only used where we dont have access to pathname
export const isNewMantineUI = (): boolean => {
  return FLAGS.TOGGLE_NEW_UI.get() || process.env.NEXT_PUBLIC_UI_TYPE === 'v2';
};
