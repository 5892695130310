import { UrlObject } from 'url';
import { SetupStepIndex, ViewBaseCoreBaseId, ViewBaseMode } from '../components/contexts/setup-context';

export class RouteUrls {
  static viewBasePageUrl(query: {
    coreBaseId: ViewBaseCoreBaseId;
    mode: ViewBaseMode;
    step: SetupStepIndex;
  }): UrlObject {
    return { pathname: '/dashboard/bases/[coreBaseId]/[mode]/[step]', query };
  }

  static devComponentPlaygroundUrl = '/dev/component-playground';
  static devSchemaViewerUrl(coreBaseId?: string): UrlObject {
    return { pathname: '/dev/schema-viewer', query: { coreBaseId } };
  }
  static devTokenUrl = '/dev/token';
  static issuesPageUrl = '/issues';
  static logsPageUrl = '/logs';
  static dashboardUrl = '/dashboard';
  static manageSubscription = '/account/manage';
  static profilePageUrl = '/account/profile';
  static signInPageUrl = '/sign-in';
  static signUpPageUrl = '/sign-up';

  // V2 URLs.
  static v2Syncs = '/v2/syncs';
  static v2Issues = '/v2/issues';
  static v2Logs = '/v2/operations';
  static v2DevOptions = '/v2/developer';
  static v2Connections = '/v2/connections';
  static v2Settings = '/v2/settings';
  static v2ManageSubscription = '/v2/subscription/manage';
  static v2Profile = '/v2/settings/profile';
  // Uset in DetailLayout. It does not accept UrlObject
  static v2TableMappings = ({ coreBaseId, isNew }: { coreBaseId: string; isNew?: boolean }): string =>
    `/v2/syncs/${coreBaseId}/table-mappings` + (isNew ? `?new=${new Date().getTime()}` : '');

  static signInPageWithRedirect(redirectUrl: string): string {
    return `${RouteUrls.signInPageUrl}?redirect_url=${encodeURIComponent(redirectUrl)}`;
  }

  static signUpPageWithRedirect(redirectUrl: string): string {
    return `${RouteUrls.signUpPageUrl}?redirect_url=${encodeURIComponent(redirectUrl)}`;
  }

  static issuesForBasePageUrl(coreBaseId: string): string {
    const filters = {
      coreBaseId,
    };

    return `${this.v2Issues}?filters=${encodeURIComponent(JSON.stringify(filters))}`;
  }

  static publicRoutePatterns = [
    '/sign-in',
    '/sign-up',
    '/health',
    '/account/signup/(.*)',
    '/api/hello',
    '/login',
    '/v2/sign-in',
    '/v2/sign-up',
  ];

  static subscriptionRoutePatterns = [
    RouteUrls.v2Syncs,
    RouteUrls.v2Issues,
    RouteUrls.v2Logs,
    RouteUrls.dashboardUrl,
    RouteUrls.logsPageUrl,
    RouteUrls.issuesPageUrl,
  ];

  static isPublicRoute(pathname: string): boolean {
    return RouteUrls.publicRoutePatterns.some((pattern) => new RegExp(pattern).test(pathname));
  }

  static isSubscribedOnlyRoute(pathname: string): boolean {
    return RouteUrls.subscriptionRoutePatterns.some((pattern) => new RegExp(pattern).test(pathname));
  }
}
