import { TextInput as MantineTextInput } from '@mantine/core';
import styles from './TextInput.module.css';

export const TextInput = MantineTextInput.extend({
  defaultProps: {
    size: 'xs',
  },
  classNames: () => {
    return { input: styles.input, error: styles.error };
  },
});
